<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view/>
</template>

<style lang="scss">
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;

  background-color: rgb(20, 28, 36);

  height: 100%;
  width: 100%;
}

#nav {
  right: 0;
  position: absolute;
  margin-right: 25px;
  margin-top: 25px;
  background-color: rgba(0, 0, 0, .7);

  padding: 7px;

  z-index: 9999;

  border-radius: 12px;

  a {
    font-weight: bold;
    color: #fff;

    &.router-link-exact-active {
      color: rgb(234, 55, 66);
    }
  }
}
</style>

<template>
  <div class="home">
    <span style="color: #000">.</span>

    <div class="row" style="margin-top:75px;">
      <div class="col-md-2 offset-md-2 offset-sm-0 p-4 pt-0">
        <div class="dg-container">
          <h3 style="padding-top:5px;">Active Keywords</h3>
          <hr style="margin:3px;">
          <span :key="word" :class="`dg-word dg-highlight`" :style="`background-color: #${categories[category].color};`" v-for="word in categories[category].keywords">
            {{ word }}
          </span>
        </div>
      </div>
      <div class="col-md-6 col-sm-10 offset-sm-1 offset-md-0 p-4 pt-0">
        <div class="dg-container">

          <div class="row" style="margin-inline: 5px">
            <div class="col-3">

              <div class="form-group ">
                <label for="categorySelect">Category Selector</label>
                <select v-model="category" class="form-control" id="categorySelect">
                  <option :key="cate.id" v-for="cate in categoryOptions" :value="cate.id" >{{cate.name}}</option>
                </select>
              </div>

            </div>

            <div class="col-3 offset-6">
              <div class="form-group">
                <label for="connection">Connection Status</label>
                <input id="connection" class="form-control" type="text"  disabled v-model="dConStatus">
              </div>
            </div>
          </div>

          <hr>

          <div class="dg-transcript">

            <a data-bs-toggle="tooltip" :title="tooltip(word)" :key="word.id" :class="`dg-word ${word.category ? 'dg-highlight' : 'dg-normal'}`" :style="`background-color: #${word.color};`" v-for="word in displayHistory">
              {{ word.word.word }}
            </a>

          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<style lang="scss" scoped>

.dg-container {
  border-radius: 12px;
  background-color: rgb(51, 68, 86);
  min-height: 320px;
}

.dg-transcript {
  padding: 10px;
  padding-top: 0px !important;
}

.dg-word {
  color: #fff;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 18px;
  display: inline-block;
  margin-top: 5px;
}

.dg-highlight {
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.dg-normal {

}
</style>

<script>
// @ is an alias to /src
import qs from 'query-string';
import {Logger as SplunkLogger} from 'splunk-logging'


export default {
  name: 'Home',
  components: {},
  data: () => {
    return {
      isConnected: false,

      deepgram: {
        url: 'wss://resistance-examining-wealth-fine.trycloudflare.com/v2/listen/stream',
        key: '',
      },
      splunk: {
        token: '',
        url: 'https://splunk.local:8088'
      },

      words: [],

      category: 'dod',
      categories: {
        dod: {
          id: 'dod',
          name: 'D.O.D',
          keywords: [
            'bomb', 'suicide', 'terrorist', 'weapon', 'murder', 'test'
          ],
          model: 'general',
          color: 'f00'
        },
        nasa: {
          id: 'nasa',
          name: 'NASA',
          keywords: [
            'rocket', 'mars', 'uranus',
          ],
          model: 'nasa',
          color: '00f'
        },
      },
      lastId: 0,
      wordsOnScreen: 77,

      conStatus: 'Preparing',
      socket: undefined,
      logger: undefined,
    };
  },
  watch: {
    'category'(val){
      this.socket.close();
      this.lifecycle();
    }
  },
  computed: {
    dConStatus() {
      return this.conStatus
    },
    categoryOptions() {
      return Object.keys(this.categories)
        .map(cateId => {
          const category = this.categories[cateId];

          return {name: category.name, id: category.id}
        })
    },
    displayHistory() {
      return this.words.map(word => {
        const pl = { id: this.lastId += 1, word }

        const category = this.categories[this.category];

        if (category.keywords.includes(word.word)) {
          pl.category = category.id;

          pl.color = category.color;
        }

        return pl
      })
    }
  },
  methods: {
    tooltip(word) {

      let lbl = `Confidence: ${word.word.confidence}`;

      if (word.category) {
        lbl += `\nCategory: ${this.categories[word.category].name}`
      }

      return lbl;
    },
    updateTranscript(list) {
      this.words = list;
    },
    transcribe(...words) {
      console.log(`trans `, words);

      for (let word of words) {
        const l = this.words.length;
        if (l > this.wordsOnScreen) {
          this.words = this.words.slice(l - this.wordsOnScreen, l);
        }

        const category = this.categories[this.category];
        if (category.keywords.includes(word.word)) {
          const pl = {
            category: category.id,
            model: category.model,
            word: word.word,
            confidence: word.confidence,
          }

          this.logger.send({ message: pl }, (err, resp, body) => {
            if (err) {
              return console.error(`failed to send event to splunk`, err);
            }
            console.log("Response from Splunk", body);
          })
        }

        this.words.push(word)
      }

    },
    lifecycle() {

      this.conStatus = 'Detecting devices'

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          console.log({ stream });
          if (!MediaRecorder.isTypeSupported('audio/webm')) {
            return alert('Browser not supported');
          }

          this.conStatus = 'Configuring device'
          const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });


          if (!this.category) {
            console.log(`no selected category`);
            return;
          }

          const category = this.categories[this.category]

          const q = {
            punctuate: false,
            keywords: category.keywords,
            model: category.model,
          };

          this.conStatus = 'Establishing connection'
          const socket = new WebSocket(`${this.deepgram.url}?${qs.stringify(q)}`);
          socket.onopen = () => {
            this.conStatus = 'Connection Established'

            this.isConnected = true;
            console.log({ event: 'onopen' });
            mediaRecorder.addEventListener('dataavailable', async (event) => {
              if (event.data.size > 0 && socket.readyState === 1) {
                socket.send(event.data);
              }
            });
            mediaRecorder.start(250);
          };

          socket.onmessage = (message) => {
            const received = JSON.parse(message.data);
            const transcript = received.channel.alternatives[0].transcript;
            if (transcript && received.is_final) {
              this.text += `${transcript} `;
              console.log(received);

              // this.transcribe(...transcript.split(' '))
              this.transcribe(...received.channel.alternatives[0].words);
            }


          };

          socket.onclose = () => {
            this.conStatus = 'Connection Interrupted'

            this.isConnected = false;
            console.log({ event: 'onclose' });
          };

          socket.onerror = (error) => {
            console.log({
              event: 'onerror',
              error,
            });
            console.error(error);
          };


          this.socket = socket;
        });
    },

  },
  mounted() {

    this.logger = new SplunkLogger(this.splunk)

    // const deepgram = new Deepgram('94bc5e34-77ea-4ced-99b4-2dfa9c3b9676', 'resistance-examining-wealth-fine.trycloudflare.com');
    this.lifecycle();
return
    const kw = this.categories[this.category].keywords;
    setInterval(() => {
      this.transcribe(...kw)
    }, 1500)
  },
  setup() {

    return {};
  },
};
</script>
